// ButtonTwo.js
import React from 'react'; 
import { Button } from './style';

export default function ButtonTwo({ children, onClick, variant, mg, wd }) {
  return (
    <Button variant={variant} mg={mg} wd={wd}>
      {children}
    </Button>
  );
}
