import styled from "styled-components";

export const Button = styled.button`
    text-align: center;
    align-items: center;
    padding: 17px 48px;
    margin: ${({ mg }) => (mg ? mg : "0")};
    width: ${({ wd }) => (wd ? wd : "auto")};
    flex-shrink: 0;
    border-radius: 4px;
    background: ${({ variant, theme }) =>
    variant === "outline" ? "transparent" : theme.verdeButton};
  color: ${({ variant, theme }) =>
    variant === "outline" ? theme.branco : theme.branco};
  border: 1px solid
    ${({ variant, theme }) =>
      variant === "outline" ? theme.branco : theme.verdeButton};
  cursor: pointer;
   transition: all 0.3s ease-in-out;
   font-size: 14px;

    &:hover {
      background: ${({ variant, theme }) =>
        variant === "outline" ? theme.branco : theme.hover.verde};
      color: ${({ variant, theme }) =>
        variant === "outline" ? theme.verdeButton : theme.branco};
    }
`;
