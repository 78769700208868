import styled, { ThemeProvider } from "styled-components";
import React from "react";
import { useState } from "react";
import Seo from "../components/Seo";
import heroAgro from "../images/agro/hero.png"
import Button from '../components/ButtonTwo';
import Checkbox from "../components/Checkbox";
import { GlobalStyle, theme } from "../styles/GlobalStyle";
import DollarIcon from "../images/agro/dollar.png";
import TimeIcon from "../images/agro/time.png";
import StatisticsIcon from "../images/agro/statistics.png";
import PersonaAgro from "../images/agro/persona.png";
import BottomIcon from "../images/agro/bottom.png"
import IllustrationAgro from "../images/Ilustracao2.png";
import SobreAgro from "../images/sobre.png";

const Layout = styled.div`
  font-family: "Segoe UI", Verdana, sans-serif;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;


const Hero = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin: 0 auto;
  height: 747px;
  background: url(${heroAgro}) no-repeat center;
  background-size: cover;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.49) 28.85%, rgba(0, 0, 0, 0.00) 100%);
    pointer-events: none;
    z-index: 1; 
  }
`;

const Content = styled.div`
  width: 1440px;
  margin: 0 auto;
  `;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 2;
    margin-left: 240px;

  img {
    margin-top: 140px;
    width: 173px;
    height: 62.233px;
  }

  h1 {
    color: ${p => p.theme.branco};
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-block: 40px;
    width: 700px;
    height: 168px;
  }

  p {
    color: ${p => p.theme.branco};
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 700px;
    height: 168px;
  }
`;


const Box = styled.div`
    display: flex;  
    align-items: center;
    gap: 39px;
`;

const Forms = styled.div`
    width: 577px;
    height: 559px;
    margin: 188px 150px 0 100px;
    padding: 54px 83px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-radius: 4px 4px 0px 0px;
    background: ${p => p.theme.bgGray2};
    flex-shrink: 0;
    z-index: 2;

  h2 {
    width: 404px;
    height: 42px;
    color: ${p => p.theme.text};
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 49px;
  }
`;

const Input = styled.input`
    width: 410px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid${p => p.theme.branco};
    background: ${p => p.theme.branco};
    padding: 20px;
    margin-bottom: 20px;

    &::placeholder {
    color: ${p => p.theme.text};
    font-size: 14px;
    font-weight: 500;
    width: 251.546px;
    }
`;

const Section = styled.section`
  display: flex;
  gap: -181px;
  padding: 40px 20px;
  background: linear-gradient(to right, #16a085, #1a2a6c);
  color: white;
  text-align: center;
`;

const Feature = styled.div`
  flex: 1;
  min-width: 250px;
`;

const IconWrapper = styled.div`
  margin-bottom: 5px;

  svg {
    width: 40px;
    height: 40px;
    color: white;
  }
`;

const Title = styled.h3`
   color: ${p => p.theme.branco};
   text-align: center;
   font-size: 22px;
   font-style: normal;
   font-weight: 500;
   line-height: normal;
   margin: 0 auto;
   width: 281px;
`;

const HelpCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 200px auto 0;
  padding: 2rem;
  gap: 90px;
  flex-wrap: wrap;
`;

const Persona = styled.div`
  flex: 1;
  min-width: 300px;
img {
  width: 491px;
  height: 829px;
  border-radius: 12px;
}
`;

const ContentSection = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

`;

const TextSection = styled.div`
h2 {
  color: ${p => p.theme.cinza};
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 38px;
}

strong {
  color: ${p => p.theme.cinza};
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 17px;
}

p {
  color: ${p => p.theme.cinza};
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 823px;
  height: 112px;
  }
`;

const Card = styled.div`
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 783px;
  border-radius: 0px 0px 4px 4px;
  background:${p => p.theme.branco};
  cursor: pointer;
  
`;

const Subtitle = styled.h2`
  color: ${p => p.theme.branco};
  display: flex;
  padding: 25px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 4px 4px 0px 0px;
  background: linear-gradient(90deg, #19A878 -0.36%, #2697C3 99.64%);
  font-size: 24px;
  font-weight: 600;
`;

const Description = styled.p`
   color: ${p => p.theme.cinza};
   font-size: 24px;
   font-style: normal;
   font-weight: 300;
   line-height: normal;
   padding: 20px;
`;

const ToggleButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  line-height: 1;
  margin: 5px 0;
`;

const AgroGest = styled.div`
  display: flex;
  margin-top: 300px;

.grid1 {
  color: ${p => p.theme.cinza};
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 701px;

  .text {
    color: ${p => p.theme.cinza};
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 65px 0 30px;
  }
}

p {
  color: ${p => p.theme.cinza};
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 30px;
}

.grid2 {
  img {
    width: 700px;
    height: 579px;
  }
}
`;

const AgroTrib = styled.div`
  display: flex;
  margin-top: 300px;
  gap: 100px;
  align-items: center;

  .grid1 {
    img {
      width: 568px;
      height: 826px;
      aspect-ratio: 284/413;
      margin-left: 60px;
      justify-content: center;
    }
  }

  
  .grid2 {
    display: flex;
    width: 700px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }
`;

const Statistics = styled.div`
  display: flex;
  width: 700px;
  height: 152px;
  padding: 30px 37px;
  align-items: center;
  gap: 68px;
  align-self: stretch;
  border-radius: 4px;
  background: linear-gradient(90deg, #159E70 -0.36%, #102E53 99.64%);
`;

const Number = styled.div`
  color: #FFF;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;

const TextNumber = styled.p`
color: #FFF;
text-align: center;
font-family: Roboto;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
`


 const Heading = styled.h2`
color: #353535;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: normal;
`;

 const Paragraph = styled.p`
color: #353535;
font-size: 24px;
font-style: normal;
width: 680px;
font-weight: 300;
line-height: normal;
`;

 const ListItemTitle = styled.h3`
color: #353535;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-bottom: -15px;
`;

 const ListItemText = styled.p`
color: #353535;
font-size: 24px;
font-style: normal;
font-weight: 300;
line-height: normal;
`;


export default function Agro() {
  const [isChecked, setIsChecked] = useState(false);
  const [openIndex, setOpenIndex] = useState(0);

  const toggleDescription = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const data = [
    {
      title: 'EFD Contribuições',
      text: 'Analise de forma detalhada todos os créditos presumidos de PIS e COFINS e faça revisões detalhadas dos dados informados nos blocos C, D e F. Analise a apuração declarada no Bloco M de modo a evitar erros e multas.'
    },
    {
      title: 'EFD ICMS-IPI (SPED Fiscal)',
      text: 'Confira de forma dinâmica as informações prestadas nos blocos cadastrais (Registros 0150, 0190), bem como o lançamento e escrituração de notas fiscais nos blocos C e D. Evite erros na apuração declarada nos registros do Bloco E e faça análises e ajustes de valores relativos a benefícios fiscais sem perder tempo.'
    },
    {
      title: 'DCTF WEB',
      text: 'Revise rapidamente informações de débitos de tributos declarados ao fisco de modo a evitar divergências, problemas com certidão negativa e multas. Identifique divergências entre os débitos declarados e aqueles informados em outras declarações acessórias.'
    },
    {
      title: 'PER/DCOMP',
      text: 'Controle de forma fácil créditos de tributos compensados e pedidos de restituição obtendo de forma dinâmica os dados informados ao fisco. Trabalhe com a análise de arquivos em lote e não perca mais tempo com controles falhos e não amigáveis.'
    },
    {
      title: 'DOCUMENTOS FISCAIS ELETRÔNICOS (NFE, CTE)',
      text: 'Crie tabelas e planilhas a partir dos dados dos documentos fiscais eletrônicos e reduza o tempo de atendimento de fiscalização, auditorias e conciliações. Ganhe agilidade na análise de informações fiscais.'
    }
  ];


  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Layout>
        <Seo title="Agro" description="Taxcel - Soluções em tecnologia para o agronegócio" image="/images/agro.png" />
        <Hero>
          <Container>
            <img src="/logo.png" alt="logo Taxcel" />
            <h1>Soluções Tributárias Especializadas para o Agronegócio</h1>
            <p>Simplifique a Gestão de Tributos no Agronegócio com a Taxcel</p>
            <Box>
              <Button>COMECE AGORA</Button>
              <Button variant="outline">SAIBA COMO PODEMOS TE AJUDAR</Button>
            </Box>
          </Container>
            <Forms>
              <h2>Fale conosco!</h2>
              <Input
                required
                type="text"
                placeholder="Nome"
              />
              <Input
                type="tel"
                placeholder="WhatsApp"
              />
              <Input
                type="email"
                placeholder="E-mail"
              />
              <Checkbox
                fs="17px"
                label="Confirmo a exatidão das informações pessoais, ciente de seu envio à Taxcel e da minha responsabilidade sobre elas."
                name="check"
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
                className="custom-checkbox"
                mt="10px"
                mb="10px"
              />
              <Button
                mg="51px auto"
                wd="379px"
              >QUERO COMEÇAR!</Button>
            </Forms>
        </Hero>
        <Section>
          <Feature>
            <IconWrapper>
              <img src={DollarIcon} alt="ícone de redução de custos" />
            </IconWrapper>
            <Title>Reduza Erros
              e Custos Operacionais</Title>
          </Feature>
          <Feature>
            <IconWrapper>
              <img src={TimeIcon} alt="ícone de otimização de tempo" />
            </IconWrapper>
            <Title>Otimize Processos
              e Economize Tempo</Title>
          </Feature>
          <Feature>
            <IconWrapper>
              <img src={StatisticsIcon} alt="imagem de dolar" />
            </IconWrapper>
            <Title>Utilize Dados para
              Tomada de Decisões</Title>
          </Feature>
        </Section>
        <Content>
          <HelpCenter>
            <Persona>
              <img src={PersonaAgro} alt="imagem de persona" />
            </Persona>
            <ContentSection>
              <TextSection>
                <h2>COMO PODEMOS TE AJUDAR?</h2>
                <strong>Principais Tributos no Agronegócio</strong>
                <p>Empresas do agronegócio lidam com um sistema tributário complexo e altamente regulado. Tal cenário envolve alto nível de dedicação e atenção rigorosa a detalhes.
                  Confira os tributos mais relevantes para o setor e seus desafios:</p>
              </TextSection>
              {data.map((item, index) => (
                <Card key={index}>
                  <Subtitle onClick={() => toggleDescription(index)}>{item.title}</Subtitle>
                  <ToggleButton onClick={() => toggleDescription(index)}>
                    {openIndex === index ? (
                      <img src={BottomIcon} alt="Expand" />
                    ) : (
                      <img src={BottomIcon} alt="Collapse" />
                    )}
                  </ToggleButton>
                  {openIndex === index && <Description>{item.text}</Description>}
                </Card>
              ))}
            </ContentSection>
          </HelpCenter>
          <AgroGest>
            <div className="grid1">
              <h3>
                Simplifique a Gestão de Tributos no Agronegócio com a Taxcel!
              </h3>
              <p className="text">
                A Taxcel é a parceira ideal para empresas do setor agropecuário que
                buscam soluções eficientes para a gestão tributária.
              </p>
              <p>
                Sabemos que o agronegócio enfrenta desafios únicos em termos de tributação e cumprimento de obrigações fiscais.
              </p>
              <p>
                Com nossa tecnologia e expertise, ajudamos você a economizar tempo com a parte operacional
                complexa de obrigações tributárias, reduzindo esforço e custos, também otimizar processos e
                aproveitar oportunidades tributárias por meio de visão de analytics que transformam analistas e gestores fiscais em cientistas de dados.
              </p>
              <Button>TESTE 7 DIAS GRÁTIS</Button>
            </div>
            <div className="grid2">
              <img src={IllustrationAgro} alt="" />
            </div>
          </AgroGest>
          <AgroTrib>
            <div className="grid1">
            <img src={SobreAgro} alt="" />
            <Statistics>
            <Number>
             +10
             <TextNumber>Anos de Experiência</TextNumber>
            </Number>
           
            <Number>
             +800
             <TextNumber>Clientes</TextNumber>
            </Number>
           
            <Number>
             +350
             <TextNumber>Grandes Corporações</TextNumber>
            </Number>
            </Statistics>
            </div>    
            <div className="grid2">
            <Heading>
        A gente sabe como o sistema tributário brasileiro é complicado
      </Heading>
      <Paragraph>
        Nossas soluções foram criadas para simplificar processos fiscais e
        maximizar resultados para o setor agropecuário. Veja como podemos ajudar:
      </Paragraph>

      <ListItemTitle>1. Automatização de Processos Fiscais</ListItemTitle>
      <ListItemText>
        Reduza esforço humano e otimize o tempo dedicado à apuração de impostos como o
        ICMS e o Funrural. Economize até 95% do tempo de sua equipe de Tax, faça
        cruzamentos sofisticados de forma simples e previna risco de erros e penalidades.
      </ListItemText>

      <ListItemTitle>2. Identificação de oportunidades tributárias</ListItemTitle>
      <ListItemText>
        Faça análises sofisticadas de forma muito simples, mesmo sem nunca ter utilizado o
        Power BI. Tenha uma visão gerencial completa, alimentada por uma fonte de verdade
        absoluta: os mesmos dados que sua empresa submete ao fisco. Isso possibilitará
        você a, por exemplo, descobrir créditos tributários que podem ser aproveitados
        para reduzir a carga fiscal.
      </ListItemText>

      <ListItemTitle>3. Atualização Constante de Legislação</ListItemTitle>
      <ListItemText>
        A Taxcel está na vanguarda em relação a mudanças na legislação que impactam o
        setor fiscal e rapidamente incorpora a suas ferramentas o que nossos clientes
        indicam como necessidade em um processo de cocriação e evolução contínua. Isso se
        aplica a todos os setores, inclusive legislação que impacta o agronegócio.
      </ListItemText>
            </div>
          </AgroTrib>
        </Content>
      </Layout>
    </ThemeProvider>
  );
}
